<template>
    <div class="common-block">
        <div class="title-wrapper">
            <div class="block"></div>
            <span class="title">{{ title }}</span>
            <slot name="right-icon"></slot>
        </div>
        <div class="common-content"  :class="{'has-padding': hasPadding}">
            <div class="common-inner" :class="{ border }">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CommonBlock',
    props: {
        title: {
            type: String,
            required: true,
        },
        rightIcon: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: true,
        },
        hasPadding: {
            type: Boolean,
            default: true,
        }
    },
};
</script>
<style lang="less" scoped>
.common-block {
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    margin-top: 10px;
}
.title-wrapper {
    position: relative;
    height: 48px;
    padding-left: 11px;
    padding-right: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .block {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 5px;
        height: 16px;
        background: #333333;
    }
    .title {
        font-size: 16px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
    }
}
.common-content {
    &.has-padding {
        padding-left: 10px;
    }
    .common-inner {
        &.border {
            border-top: 1px solid #f0f0f0;
        }
    }
}
</style>
