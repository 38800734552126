export default function getQuery() {
    var url = decodeURI(location.search);
    var query = {
    };
    if (url.indexOf('?') !== -1) {
        var str = url.substr(1);
        var pairs = str.split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            // @ts-ignore
            query[pair[0]] = pair[1];
        }
    }
    return query; // 返回对象
}
