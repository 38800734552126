<template>
    <page class="home-page" title="首页" :hasBack="false" textAlign="center">
        <nav-page :roleType="1">
            <van-swipe :autoplay="3000" lazy-render>
                <van-swipe-item v-for="image in bannerList" :key="image">
                    <van-image :src="image.img"  fit="cover" class="swiper-img" />
                    <!--                    <img :src="image.img" class="swiper-img" />-->
                </van-swipe-item>
            </van-swipe>
            <van-notice-bar class="notice" left-icon="volume-o" :text="`${hourName}，${userName}~`">
                <template v-slot:left-icon>
                    <img class="left-icon" src="@/assets/images/home/voice.png" alt="" />
                </template>
                <template v-slot:right-icon>
                    <img class="right-icon" @click="changePickVisible" src="@/assets/images/home/convert.png" alt="" />
                </template>
            </van-notice-bar>
            <van-popup ref="vantPicker" v-model:show="studentPickerVisible" round position="bottom">
                <van-picker
                    title="选择学生"
                    :columns="studentList"
                    @confirm="studentConfirm"
                    @cancel="changePickVisible"
                />
            </van-popup>
            <div class="menu-list">
                <div v-for="item in menuList" class="menu-item" @click="menuClick(item.link)" :key="item.name">
                    <img :src="item.icon" alt="" />
                    <div class="title">{{ item.name }}</div>
                </div>
            </div>
            <common-block class="school-notice" title="校园公告">
                <template v-if="!schoolNoticeHasLoad || schoolNoticeList.length">
                    <notice-item
                        v-for="item in schoolNoticeList"
                        :title="item.title"
                        :time="item.createTime"
                        :id="item.id"
                        :key="item.id"
                    />
                    <read-more @read-more="readNoticeMore" />
                </template>
                <div class="empty-wrapper" v-else>
                    <empty />
                </div>
            </common-block>
            <common-block class="trajectory" :hasPadding="false" :title="`${currentChooseUser.name}同学今天在校行为轨迹和表现`">
                <template v-if="!stepListHasLoad || stepList.length">
                    <common-step :stepList="stepList" v-if="hasBehavior" />
                    <div class="behavior-bg" v-if="!hasBehavior">
                        <div class="mask">
                            <div class="tips-title">提示！</div>
                            <div class="tips-content">
                                暂未开通服务，
                                无法关注学生的行为轨迹额~
                            </div>
                            <img src="@/assets/images/home/down.png" alt="">
                            <van-button class="confirm-btn" round type="primary" >马上去开通</van-button>
                        </div>
                    </div>
                </template>
                <div class="empty-wrapper" v-else>
                    <empty />
                </div>
            </common-block>
            <div class="has-bottom">
                已经到底了
            </div>
        </nav-page>
    </page>
</template>
<script>
import Page from '@/components/page/Page.vue';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import NoticeItem from '@/components/noticeItem/NoticeItem.vue';
import { reactive, ref } from 'vue';
import { Swipe, SwipeItem, NoticeBar, Image as VanImage, Toast, Picker, Popup, Button } from 'vant';
import ReadMore from '@/components/readMore/ReadMore';
import Empty from '@/components/empty/Empty';
import CommonStep from '@/components/commonStep/CommonStep';
import { useRouter } from 'vue-router';
import NavPage from '@/components/navPage/NavPage';
import commonRequest from '@/services';
import {getStorage, setStorage} from '@/utils/storage';
export default {
    name: 'home',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [NoticeBar.name]: NoticeBar,
        [VanImage.name]: VanImage,
        [NoticeItem.name]: NoticeItem,
        [ReadMore.name]: ReadMore,
        [Empty.name]: Empty,
        [CommonStep.name]: CommonStep,
        [NavPage.name]: NavPage,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [Button.name]: Button,
    },
    setup() {
        const userName = getStorage('userName');
        const now = new Date();
        const hour = now.getHours();
        let hourName = '';
        if (hour < 12){
            hourName  = '早上好'
        } else if (hour < 17) {
            hourName = '下午好'
        } else {
            hourName  = '晚上好'
        }
        const router = useRouter();
        const readNoticeMore = () => {
            router.push({
                path: '/schoolNotice',
            });
        };
        const bannerList = ref([]);
        commonRequest({ action: 11001 }).then((res) => {
            if (res.data && res.data.rows) {
                bannerList.value = res.data.rows;
            }
        });
        const currentChooseUser = reactive({
            name: '',
            id: ''
        });
        const studentList = ref([]);
        const studentPickerVisible = ref(false);
        const changePickVisible = () => {
            studentPickerVisible.value = !studentPickerVisible.value
        };
        const studentConfirm = (value) => {
            currentChooseUser.id = value.id;
            currentChooseUser.name = value.text;
            setStorage('chooseStudentId', value.id);
        };

        const stepList = ref([
        ]);
        const stepListHasLoad = ref(true);
        const hasBehavior = ref(false);
        const fetchStudentLog = () => {
            commonRequest({ action: 11016, studentUserid: currentChooseUser.id}).then((res) => {
                hasBehavior.value = res.data.status === 1
                if (res.data.behaviorRecordListResponses && res.data.behaviorRecordListResponses.length) {
                    stepList.value = res.data.behaviorRecordListResponses[0].behaviorRecordLogResponseList.map((item, index) => ({
                        title: item.content,
                        content: item.createTime,
                        id: index
                    }))
                }
            })
        };

        commonRequest({ action: 11009 }).then((res) => {
            if (res.data) {
                studentList.value = res.data.map(item => ({
                    id: item.studentUserid,
                    text: item.studentName
                }));
                if (res.data.length) {
                    const chooseStudentId = getStorage('chooseStudentId');
                    if (!chooseStudentId) {
                        currentChooseUser.id = res.data[0].studentUserid;
                        currentChooseUser.name = res.data[0].studentName;
                        setStorage('chooseStudentId', res.data[0].studentUserid);
                    } else {
                        currentChooseUser.id = chooseStudentId;
                        currentChooseUser.name = res.data.find(item => item.studentUserid == chooseStudentId)?.studentName;
                    }
                }
                fetchStudentLog();

            }
        });

        const menuList = reactive([
            {
                name: '我要请假',
                link: '/askLeave',
                icon: require('@/assets/images/home/ask_live.png'),
            },
            {
                name: '考勤记录',
                link: '/myAttendance',
                icon: require('@/assets/images/home/attendance.png'),
            },
            {
                name: '一卡通',
                link: 'disable',
                icon: require('@/assets/images/home/card.png'),
            },
            {
                name: '成绩查询',
                link: 'disable',
                icon: require('@/assets/images/home/search.png'),
            },
        ]);
        const schoolNoticeList = ref([]);
        const schoolNoticeHasLoad = ref(true);


        commonRequest({ action: 11002, type: 1, pageNum: 1, pageSize: 2 }).then((res) => {
            schoolNoticeHasLoad.value = true;
            if (res.data && res.data.rows) {
                schoolNoticeList.value = res.data.rows;
            }
        });

        const menuClick = (link) => {
            if (link === 'disable') {
                Toast('正在建设中');
                return;
            }
            router.push({
                path: link,
            });
        };
        return {
            bannerList,
            menuList,
            schoolNoticeList,
            schoolNoticeHasLoad,
            stepList,
            stepListHasLoad,
            studentList,
            studentPickerVisible,
            currentChooseUser,
            studentConfirm,
            changePickVisible,
            readNoticeMore,
            menuClick,
            hasBehavior,
            userName,
            hourName
        };
    },
};
</script>
<style lang="less" scoped>
.home-page {
    padding-bottom: 35px;
    .swiper-img {
        width: 100%;
        height: 123px;
        /deep/ .van-image__img {
            border-radius: 10px;
        }
    }
    .notice {
        height: 33px;
        margin: 10px 0;
        padding: 0 10px;
        background: #fff;
        font-size: 14px;
        font-family: PingFang SC, serif;
        color: #666666;
        .left-icon {
            width: 12px;
            margin-right: 10px;
        }
        .right-icon {
            width: 13px;
        }
    }
    .menu-list {
        display: flex;
        height: 100px;
        background: #ffffff;
        border-radius: 10px;
        .menu-item {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .title {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            line-height: 20px;
            color: #666666;
            margin-top: 6px;
        }
        img {
            width: 44px;
        }
    }
    .empty-wrapper {
        height: 240px;
    }

    .trajectory {
        margin-top: 10px;
    }
    .behavior-bg {
        background: #fff;
        position: relative;
        height: 250px;

        .mask {
            box-sizing: border-box;
            height: 100%;
            background: rgba(51, 51, 51, 0.5);
            border-radius: 10px 10px 0 0;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .tips-title {
            font-size: 18px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 25px;
            color: #FFFFFF;
        }
        .tips-content {
            width: 188px;
            font-size: 15px;
            font-family: PingFang SC, serif;
            line-height: 21px;
            color: #FFFFFF;
            text-align: center;
            margin: 5px auto 10px;
        }
        img {
            width: 12px;
            height: 18px;
            margin-bottom: 23px;
        }
        /deep/ .van-button {
            width: 215px;
            height: 49px;
        }
    }
    .has-bottom {
        font-size: 12px;
        font-family: PingFang SC, serif;
        line-height: 17px;
        color: #808080;
        margin-top: 35px;
        text-align: center;
    }
}
</style>
