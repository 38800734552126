<template>
    <van-nav-bar
        @click-left="clickLeft"
        class="header"
        :title="title"
        :left-arrow="hasBack"
        :class="{ 'align-left': textAlign === 'left' }"
        safe-area-inset-top
    />
</template>
<script>
import { NavBar } from 'vant';
import { useRouter } from 'vue-router';
export default {
    name: 'HeaderCom',
    components: {
        [NavBar.name]: NavBar,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        hasBack: {
            type: Boolean,
            default: true,
        },
        textAlign: {
            type: String,
            default: 'left',
        },
        backHome: {
            type: String,
        }
    },
    setup(props) {
        const router = useRouter();
        const clickLeft = () => {
            if (props.backHome) {
                router.replace(props.backHome === 'teacherHome' ? '/teacherHome' : '/')
            } else {
                router.back();
            }
        };
        return {
            clickLeft,
        };
    },
};
</script>
<style lang="less" scoped>
.header {
    height: 44px;
    background: #fff;
    align-items: center;
    flex-shrink: 0;
    img {
        margin: 0 4.5px 0 15px;
    }
    /deep/ .van-nav-bar__title {
        font-weight: bold;
    }
    /deep/ .van-nav-bar__content {
        font-size: 18px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        color: #333333;
        flex: 1;
        height: 100%;
    }
    /deep/ .van-icon {
        color: #333333;
        font-weight: bold;
    }
    &.align-left {
        color: red;
        /deep/ .van-nav-bar__title {
            margin: 0 0 0 36px;
        }
    }
}
</style>
