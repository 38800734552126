import axios from 'axios';
import { Toast } from 'vant';
import paramsParse from './paramsParse';
import QS from 'qs';
import { getStorage } from './storage';

const BaseUrl = '/qy/api/v1';

const service = axios.create({
    baseURL: BaseUrl,
    timeout: 20000,
});
service.interceptors.request.use(
    (config) => {
        if (config.method === 'post' && config.data.action) {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
            const token = getStorage('token');
            console.log(token, config.data, 'token');
            if (token) {
                config.data.token = token;
            }
            config.data.timestamp = Math.floor(new Date().getTime() / 1000);
            const sign = paramsParse(config.data);
            config.data.sign = sign;
            config.data = QS.stringify({
                ...config.data,
            });
        }
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (!(res.code === '0' || res.code === 0)) {
            Toast(res.msg);
            return Promise.reject(new Error(res.msg || 'Error'));
        } else {
            return res;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default service;
