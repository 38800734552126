import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/patriarch/home/Home.vue';
import {getStorage, setStorage} from "@/utils/storage";
import { CORP_ID } from '@/constants';
import commonRequest from '@/services';
import getQuery from "@/utils/getQuery";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            keepAlive: true,
        },
    },
    // 消息详情
    {
        path: '/messageDetail',
        name: 'MessageDetail',
        component: () =>
            import(/* webpackChunkName: "messageDetail" */ '../views/patriarch/messageDetail/MessageDetail.vue'),
    },
    {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackChunkName: "user" */ '../views/patriarch/user/User.vue'),
    },
    {
        path: '/publish',
        name: 'Publish',
        component: () => import(/* webpackChunkName: "publish" */ '../views/patriarch/publish/Publish.vue'),
    },
    // 学校公告列表
    {
        path: '/schoolNotice',
        name: 'SchoolNotice',
        component: () =>
            import(/* webpackChunkName: "schoolNotice" */ '../views/patriarch/schoolNotice/SchoolNotice.vue'),
    },
    // 文章公告详情
    {
        path: '/noticeDetail',
        name: 'NoticeDetail',
        component: () =>
            import(/* webpackChunkName: "noticeDetail" */ '../views/patriarch/noticeDetail/NoticeDetail.vue'),
    },
    // 请假申请
    {
        path: '/askLeave',
        name: 'AskLeave',
        component: () => import(/* webpackChunkName: "askLeave" */ '../views/patriarch/askLeave/AskLeave.vue'),
    },
    // 我的考勤
    {
        path: '/myAttendance',
        name: 'MyAttendance',
        component: () =>
            import(/* webpackChunkName: "myAttendance" */ '../views/patriarch/myAttendance/MyAttendance.vue'),
    },
    // 我的请假
    {
        path: '/myLeave',
        name: 'MyLeave',
        component: () => import(/* webpackChunkName: "myLeave" */ '../views/patriarch/myLeave/MyLeave.vue'),
    },
    // 请假详情
    {
        path: '/leaveDetail',
        name: 'LeaveDetail',
        component: () => import(/* webpackChunkName: "leaveDetail" */ '../views/patriarch/leaveDetail/LeaveDetail.vue'),
    },
    // 服务介绍
    {
        path: '/serviceIntroduce',
        name: 'ServiceIntroduce',
        component: () =>
            import(
                /* webpackChunkName: "serviceIntroduce" */ '../views/patriarch/serviceIntroduce/ServiceIntroduce.vue'
            ),
    },
    // 服务购买
    {
        path: '/serviceBuy',
        name: 'ServiceBuy',
        component: () => import(/* webpackChunkName: "serviceBuy" */ '../views/patriarch/serviceBuy/ServiceBuy.vue'),
    },
    // 绑定的学生列表
    {
        path: '/studentList',
        name: 'StudentList',
        component: () => import(/* webpackChunkName: "studentList" */ '../views/patriarch/studentList/StudentList.vue'),
    },
    // 添加学生
    {
        path: '/addStudent',
        name: 'AddStudent',
        component: () => import(/* webpackChunkName: "addStudent" */ '../views/patriarch/addStudent/AddStudent.vue'),
    },
    // 学生详情
    {
        path: '/studentDetail',
        name: 'StudentDetail',
        component: () =>
            import(/* webpackChunkName: "studentDetail" */ '../views/patriarch/studentDetail/StudentDetail.vue'),
    },
    // 我的成绩
    {
        path: '/myScore',
        name: 'MyScore',
        component: () => import(/* webpackChunkName: "myScore" */ '../views/patriarch/myScore/MyScore.vue'),
    },
    // 消息推送
    {
        path: '/messagePush',
        name: 'MessagePush',
        component: () => import(/* webpackChunkName: "messagePush" */ '../views/patriarch/messagePush/MessagePush.vue'),
    },
    // 设置
    {
        path: '/setting',
        name: 'Setting',
        component: () => import(/* webpackChunkName: "setting" */ '../views/patriarch/setting/Setting.vue'),
    },

    // 教导端首页
    {
        path: '/teacherHome',
        name: 'TeacherHome',
        component: () => import(/* webpackChunkName: "teacherHome" */ '../views/teacher/home/Home.vue'),
        meta: {
            keepAlive: true,
        },
    },
    // 审批列表
    {
        path: '/approveList',
        name: 'ApproveList',
        component: () => import(/* webpackChunkName: "approveList" */ '../views/teacher/approveList/ApproveList.vue'),
    },
    // 请假审批
    {
        path: '/approveDetail',
        name: 'ApproveDetail',
        component: () =>
            import(/* webpackChunkName: "approveDetail" */ '../views/teacher/approveDetail/ApproveDetail.vue'),
    },
    // 班级人脸采集
    {
        path: '/classFace',
        name: 'ClassFace',
        component: () => import(/* webpackChunkName: "classFace" */ '../views/teacher/classFace/ClassFace.vue'),
    },
    // 学生考勤
    {
        path: '/studentAttendance',
        name: 'StudentAttendance',
        component: () =>
            import(
                /* webpackChunkName: "studentAttendance" */ '../views/teacher/studentAttendance/StudentAttendance.vue'
            ),
    },
    // 教导端个人中心
    {
        path: '/teacherUser',
        name: 'TeacherUser',
        component: () => import(/* webpackChunkName: "teacherUser" */ '../views/teacher/user/User.vue'),
    },
    // 学生评价
    {
        path: '/comments',
        name: 'Comments',
        component: () => import(/* webpackChunkName: "comments" */ '../views/teacher/comments/Comments.vue'),
    },
    // 学生评价详情
    {
        path: '/commentsDetail',
        name: 'CommentsDetail',
        component: () =>
            import(/* webpackChunkName: "commentsDetail" */ '../views/teacher/commentsDetail/CommentsDetail.vue'),
    },
    // 学生评价详情
    {
        path: '/studentCall',
        name: 'StudentCall',
        component: () => import(/* webpackChunkName: "studentCall" */ '../views/teacher/studentCall/StudentCall.vue'),
    },
    // 待请假
    {
        path: '/teacherAskLeave',
        name: 'TeacherAskLeave',
        component: () => import(/* webpackChunkName: "teacherAskLeave" */ '../views/teacher/askLeave/index.vue'),
    },
    //
    {
        path: '/cancelLeave',
        name: 'CancelLeave',
        component: () => import(/* webpackChunkName: "cancelLeave" */ '../views/teacher/cancelLeave/CancelLeave.vue'),
    },

    // 班导端学生请假列表
    {
        path: '/approveListSecurity',
        name: 'ApproveListSecurity',
        component: () => import(/* webpackChunkName: "approveListSecurity" */ '../views/security/approveList/ApproveList.vue'),
    },
    // 班导端学生请假详情
    {
        path: '/approveDetailSecurity',
        name: 'ApproveDetailSecurity',
        component: () => import(/* webpackChunkName: "approveDetailSecurity" */ '../views/security/approveDetail/ApproveDetail.vue'),
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isLogin = window.sessionStorage.getItem('isLogin');
    if (!isLogin) {
        const query = getQuery();
        console.log(query, 'query')
        const res = await commonRequest({
            action: 20001,
            code: query.code,
            corpID: CORP_ID,
            // code: 'test2',
        });
        console.log(res, 'res');
        if (res.data) {
            setStorage('token', res.data.token);
            setStorage('userName', res.data.userName);
            setStorage('userId', res.data.userId);
            setStorage('roles', res.data.roles);
            window.sessionStorage.setItem('isLogin', '1');
            document.title = res.data.schoolName +' 家校沟通';
            if (res.data.roles === 1) {
                // console.log(window.location.pathname, 'window.location.pathname')
                if (window.location.pathname !== '/') {
                    next({path:  window.location.pathname, query: {id: query.id, backHome: 'teacherHome'}})
                } else {
                    next({path: '/teacherHome'})
                }
            }  else if (res.data.roles === 2) {
                if (window.location.pathname !== '/') {
                    next({path: window.location.pathname, query: {id: query.id, backHome: 'patriarchHome'}})
                } else {
                    next({path: '/'})
                }
            } else if (res.data.roles === 3) {
                next({path: '/approveListSecurity'})
            }
        }
    } else {
        next();
    }
});

export default router;
