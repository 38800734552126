<template>
    <div class="notice-item">
        <div class="content">
            <div class="text one-line-ellipsis">{{ title }}</div>
        </div>
        <div class="time-wrapper">
            <div class="time">{{ time }}</div>
            <div class="detail" @click="readDetail">
                <span class="text">查看详情</span>
                <van-icon name="arrow" />
            </div>
        </div>
    </div>
</template>
<script>
import { Icon } from 'vant';
import { useRouter } from 'vue-router';

export default {
    components: {
        [Icon.name]: Icon,
    },
    name: 'NoticeItem',
    props: {
        id: {
            type: Number,
        },
        title: {
            type: String,
            required: true,
        },
        time: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter();
        const readDetail = () => {
            router.push({
                path: '/noticeDetail',
                query: { id: props.id },
            });
        };
        return {
            readDetail,
        };
    },
};
</script>
<style lang="less" scoped>
.notice-item {
    padding: 15px 10px 17px;
    border-bottom: 1px solid #f0f0f0;
    .content {
        padding-right: 25px;
        .text {
            font-size: 15px;
            font-family: PingFang SC, serif;
            line-height: 21px;
            color: #333333;
        }
    }
    .time-wrapper {
        margin-top: 5px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        .time {
            font-size: 12px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            color: #666666;
        }
        .detail {
            .text {
                font-size: 12px;
                font-family: PingFang SC, serif;
                color: #666666;
                margin-right: 5px;
            }
        }
    }
}
</style>
