import { MD5_KEY } from '@/constants';
import md5 from 'md5';
export default function paramsParse(paramsData) {
    const sortKeys = Object.keys(paramsData).sort();
    let md5Str = '';
    sortKeys.forEach((key) => {
        md5Str += `${key}=${paramsData[key]}&`;
    });
    md5Str = md5Str.slice(0, md5Str.length - 1) + MD5_KEY;
    return md5(md5Str);
}
