<template>
    <div class="read-more" @click="readMore">
        <span class="text">查看更多</span>
        <van-icon name="arrow" />
    </div>
</template>
<script>
import { Icon } from 'vant';

export default {
    components: {
        [Icon.name]: Icon,
    },
    name: 'ReadMore',
    emits: ['readMore'],
    setup(props, { emit }) {
        const readMore = () => {
            emit('readMore');
        };
        return {
            readMore,
        };
    },
};
</script>
<style lang="less" scoped>
.read-more {
    height: 66px;
    text-align: center;
    line-height: 66px;
    .text {
        font-size: 12px;
        font-family: PingFang SC, serif;
        font-weight: 400;
        color: #808080;
        margin-right: 5px;
    }
}
</style>
