<template>
    <div class="empty-wrapper center">
        <img src="@/assets/images/common/empty.png" alt="" />
        <div class="empty-text">
            {{ emptyText }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'empty',
    props: {
        emptyText: {
            type: String,
            default: '暂时还没有额~',
        },
    },
};
</script>
<style lang="less" scoped>
.empty-wrapper {
    flex-direction: column;
    img {
        width: 127px;
        margin-top: -50px;
    }
    .empty-text {
        font-size: 12px;
        font-family: PingFang SC, serif;
        color: #999999;
        margin-top: 10px;
    }
}
</style>
