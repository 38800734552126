<template>
    <div class="common-step">
        <van-steps direction="vertical">
            <van-step v-for="item in stepList" :key="item.id"  :class="{approve: styleApprove}">
                <div class="title">{{ item.title }}</div>
                <div class="content" :style="{color: item.color}">{{ item.content }}</div>
                <template v-slot:active-icon>
                    <div class="icon"></div>
                </template>
                <template v-slot:inactive-icon>
                    <div class="icon"></div>
                </template>
            </van-step>
        </van-steps>
    </div>
</template>
<script>
import { Step, Steps } from 'vant';
export default {
    name: 'CommonStep',
    components: {
        [Steps.name]: Steps,
        [Step.name]: Step,
    },
    props: {
        stepList: {
            type: Array,
            default: () => [],
        },
        styleApprove: {
            type: Boolean,
            default: false,
        }
    },
};
</script>
<style lang="less" scoped>
.common-step {
    margin-left: -10px;
    margin-top: 2.5px;
    padding-bottom: 12.5px;
    .icon {
        box-sizing: border-box;
        width: 10px;
        height: 10px;
        background: #fff;
        border: 2px solid #333333;
        border-radius: 50%;
    }
    .approve {
        .title {
            font-size: 13px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            line-height: 19px;
            color: #333333;
        }
        .content {
            font-size: 15px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            line-height: 21px;
            color: #333333;
        }
    }
    .title {
        font-size: 13px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 19px;
        color: #333333;
    }
    .content {
        font-size: 12px;
        font-family: PingFang SC, serif;
        line-height: 17px;
        color: #666666;
    }
    /deep/ .van-step--vertical:not(:last-child)::after {
        border: none;
    }
    /deep/ .van-step__line {
        background: #333333;
    }
    /deep/ .van-step--vertical {
        padding: 12.5px 12.5px 12.5px 0;
    }
}
</style>
