<template>
    <div class="smart-campus-web">
        <router-view #default="{ Component }">
            <keep-alive>
                <component :is="Component" v-if="$route.meta.keepAlive" />
            </keep-alive>
            <component :is="Component" v-if="!$route.meta.keepAlive" />
        </router-view>
        <!--        <router-view />-->
    </div>
</template>
<style lang="less">
.smart-campus-web {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
</style>
