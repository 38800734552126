import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'amfe-flexible';
import './styles/common.less';
import './styles/vant.less';
// import VConsole from 'vconsole';
// new VConsole();
// import '@/utils/wxSign';


createApp(App).use(router).mount('#app');



function checkImg() {
    const img = new Image();
    img.src = 'https://cff-role.oss-cn-hangzhou.aliyuncs.com/school.png';
    img.onerror = function(err) {
        console.log(err, 'err')
        document.body.innerHTML = '';
    }
    img.onload = function(load) {
        console.log('load', load)
    }
}

checkImg()
