<template>
    <div class="nav">
        <div class="nav-item" @click="navClick(item.link)" v-for="item in navList" :key="item.link">
            <img :src="item.link === path ? item.activeIcon : item.icon" alt="" />
            <div class="name">{{ item.name }}</div>
        </div>
    </div>
</template>
<script>
import { computed, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Toast } from 'vant';
export default {
    name: 'NavBar',
    props: {
        roleType: Number,
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const path = computed(() => route.path);
        const allNav = [
            {
                name: '首页',
                link: '/',
                icon: require('@/assets/images/menu/home.png'),
                activeIcon: require('@/assets/images/menu/home_active.png'),
                role: 1,
            },
            {
                name: '首页',
                link: '/teacherHome',
                icon: require('@/assets/images/menu/home.png'),
                activeIcon: require('@/assets/images/menu/home_active.png'),
                role: 2,
            },
            {
                name: '应用',
                link: 'disable',
                icon: require('@/assets/images/menu/application.png'),
                activeIcon: require('@/assets/images/menu/application_active.png'),
                role: 1,
            },
            {
                name: '代办',
                link: '/approveList',
                icon: require('@/assets/images/menu/agent.png'),
                activeIcon: require('@/assets/images/menu/agent_active.png'),
                role: 2,
            },
            {
                name: '发布',
                link: 'disable',
                icon: require('@/assets/images/menu/publish.png'),
                activeIcon: require('@/assets/images/menu/publish_active.png'),
                role: 2,
            },
            {
                name: '日程',
                link: 'disable',
                icon: require('@/assets/images/menu/schedule.png'),
                activeIcon: require('@/assets/images/menu/schedule_active.png'),
                role: 2,
            },
            {
                name: '我的',
                link: '/user',
                icon: require('@/assets/images/menu/user.png'),
                activeIcon: require('@/assets/images/menu/user_active.png'),
                role: 1,
            },
            {
                name: '我的',
                link: '/teacherUser',
                icon: require('@/assets/images/menu/user.png'),
                activeIcon: require('@/assets/images/menu/user_active.png'),
                role: 2,
            },
        ].filter((item) => item.role === props.roleType);
        console.log(props.roleType, 'props.roleType', allNav);
        const navList = reactive(allNav);
        const navClick = (link) => {
            if (link === 'disable') {
                Toast('正在建设中');
                return;
            }
            router.push({
                path: link,
            });
        };
        return {
            navList,
            path,
            navClick,
        };
    },
};
</script>
<style lang="less" scoped>
.nav {
    width: 100%;
    height: 49px;
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    .nav-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.router-link-active {
            .name {
                color: #07c160;
            }
        }
        img {
            width: 25px;
        }
        .name {
            font-size: 12px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            line-height: 16.5px;
            color: #333333;
        }
    }
}
</style>
