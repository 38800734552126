import { BASIC_STORAGE } from '@/constants';

export function setStorage(key, value) {
    let newValue = value;
    if (!(typeof value === 'string')) {
        newValue = JSON.stringify(value);
    }
    window.localStorage.setItem(BASIC_STORAGE + key, newValue);
}

export function getStorage(key) {
    let res = window.localStorage.getItem(BASIC_STORAGE + key);
    try {
        if (res != null) {
            res = JSON.parse(res);
        }
    } catch (e) {
        // console.error(e, 'getStorage');
    }
    return res;
}

export function delStorage(key) {
    window.localStorage.removeItem(BASIC_STORAGE + key);
}
