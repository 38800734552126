<template>
    <div class="nav-page">
        <slot></slot>
        <nav-bar :roleType="roleType" />
    </div>
</template>
<script>
import Nav from '@/components/nav/Nav.vue';
export default {
    name: 'NavPage',
    props: {
        roleType: Number,
    },
    components: {
        [Nav.name]: Nav,
    },
};
</script>
<style lang="less" scoped>
.nav-page {
    min-height: 100%;
    padding-bottom: 49px;
    box-sizing: border-box;
}
</style>
