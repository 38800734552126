<template>
    <div class="common-page">
        <header-com :title="title" :hasBack="hasBack" :textAlign="textAlign" :backHome="backHome" />
        <div class="container" :class="{ padding: hasPadding }">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import HeaderCom from '@/components/header/Header.vue';
export default {
    name: 'Page',
    components: {
        [HeaderCom.name]: HeaderCom,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        hasPadding: {
            type: Boolean,
            default: true,
        },
        hasBack: {
            type: Boolean,
        },
        textAlign: {
            type: String,
        },
        backHome: {
            type: String
        }
    },
};
</script>
<style lang="less" scoped>
.common-page {
    height: 100%;
    background: #f7f9fa;
    display: flex;
    flex-direction: column;
}
.container {
    flex: 1;
    overflow: auto;
    &.padding {
        padding: 10px 15px;
    }
}
</style>
